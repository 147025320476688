<template>
  <div class="box">
    <audio
      id="audio"
      class="audio"
      loop="loop"
      preload="auto"
      controls="controls"
      src="http://cdn.topartsintermational.com/newYear.mp3"
    ></audio>
    <div class="limit-box">
      <el-button
        type="danger"
        class="index"
        @click="() => this.$router.push('/')"
        >{{ $t('message.game.back') }}</el-button>
      <el-button
        class="intro-btn"
        type="danger"
        @click="() => (this.introVisible = true)"
        >{{ $t('message.game.intro') }}</el-button>

      <div class="door" v-show="visible">
        <img src="../../assets/door.gif" alt="" />
         <!-- <img src="http://cdn.topartsintermational.com/door.gif" alt="" /> -->
        <video
          :src="src"
          controls
          class="endVideo"
          v-show="showVideo"
          autoplay
          muted
        ></video>
      </div>
      <div class="door" v-show="!visible">
        <img src="../../assets/door.png" style="width: 724px" />
        <div class="door-left door-part">
          <!-- 左门 -->
          <draggable
            :forceFallback="true"
            :list="leftBox"
            :options="{ group: 'leftDoor' }"
            @start="leftStart"
            @end="leftEnd"
            class="leftDrag"
            style="height: 150px"
            :move="onLeftMove"
          >
            <img
              v-for="element in leftBox"
              :key="element.id"
              :src="element.url"
              class="door-box"
            />
          </draggable>
        </div>
        <div class="door-right door-part">
          <!-- 右门 -->
          <draggable
            :forceFallback="true"
            :list="rightBox"
            :options="{ group: 'rightDoor' }"
            @start="rightStart"
            @end="rightEnd"
            class="rightDrag"
            style="height: 150px"
            :move="onRightMove"
          >
            <img
              v-for="element in rightBox"
              :key="element.id"
              class="door-box"
              :src="element.url"
            />
          </draggable>
        </div>
      </div>

      <div class="list-box">
        <div class="left-list list">
          <h3>{{ $t('message.game.leftDoor') }}</h3>
          <draggable
            :forceFallback="true"
            :list="leftList"
            :options="{
              group: { name: left, pull: 'clone' },
              filter: '.undraggable',
              sort: false,
            }"
            @end="onLeftEnd"
            :move="onLeftMove"
            class="dragArea"
          >
            <img
              v-show="!element.flag"
              v-for="element in leftList"
              :key="element.id"
              :class="{ undraggable: element.flag }"
              :src="element.url"
              class="left-item list-img"
              width="100px"
              height="150px"
            />
          </draggable>
        </div>
        <div class="right-list list">
          <h3>{{ $t('message.game.rightDoor') }}</h3>
          <draggable
            :forceFallback="true"
            :list="rightList"
            :options="{
              group: { name: right, pull: 'clone' },
              filter: '.undraggable',
              sort: false,
            }"
            @end="onRightEnd"
            :move="onRightMove"
            class="dragArea"
          >
            <img
              v-show="!element.flag"
              v-for="element in rightList"
              :key="element.id"
              :class="{ undraggable: element.flag }"
              :src="element.url"
              class="right-item list-img"
              width="100px"
              height="150px"
            />
          </draggable>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="cont">
      <div class="icon">
        <a
          href="https://twitter.com/visitshaanxi"
          target="_blank"
        >
          <img src="../../assets/t.png" width="30px" height="30px" />
        </a>
        <a
          href="https://www.facebook.com/visitshaanxi"
          target="_blank"
        >
          <img src="../../assets/f.png" width="30px" height="30px" />
        </a>
      </div>
      <div class="cont-menu">
        <el-row>
          <el-col :span="5">{{ $t("message.footer.left.title") }}</el-col>
          <el-col :span="19">
            <p>{{ $t("message.footer.left.item1") }}</p>
            <p>{{ $t("message.footer.left.item2") }}</p>
          </el-col>
        </el-row>
      </div>
      <div class="cont-menu">
        <!-- <el-row>
          <el-col :span="4">{{ $t("message.footer.right.title") }}</el-col>
          <el-col :span="20"
            ><p>{{ $t("message.footer.right.item") }}</p></el-col
          >
        </el-row> -->
        <el-row>
          <el-col :span="7">{{ $t("message.footer.thanks.title") }}</el-col>
          <el-col :span="17"
            ><p>{{ $t("message.footer.thanks.item") }}</p></el-col
          >
        </el-row>
      </div>
      <!-- <div class="cont-menu all-line">
        <div class="title">{{ $t("message.footer.thanks.title") }}</div>
        <div class="des">{{ $t("message.footer.thanks.item") }}</div>
      </div> -->
    </div>
    <div class="qrcode">
      <img src="../../assets/qrcode1.jpg" alt="" class="img">
      <img src="../../assets/qrcode2.jpg" alt="" class="img">
    </div>
    <div style="text-align: center; padding-bottom: 10px;">
      <a
        href="https://beian.miit.gov.cn"
        rel="nofollow noopener noreferrer"
        target="_blank"
        style="color: #fff; text-decoration: none"
      >
        陕ICP备20012594号-2
      </a>
    </div>
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
      @close="onClose"
    >
      <video
        src="http://image.wearetogether.com.cn/game-fir.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
    <!-- 活动介绍模态框 -->
    <el-dialog
      :visible.sync="introVisible"
      width="1200px"
      custom-class="dialog"
      destroy-on-close
    >
      <div class="introBox">
        <h1>{{ $t('message.game.desTitle') }}</h1>
        <p>
          {{ $t('message.game.des') }}
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { shuffle } from '../../utils/utils'
export default {
  name: 'Game',
  components: { draggable },
  data () {
    return {
      left: 'leftDoor',
      right: 'rightDoor',
      visible: false,
      dialogVisible: true,
      introVisible: false,
      showVideo: false,
      src: 'http://cdn.topartsintermational.com/end1.mp4',
      leftBox: [],
      leftList: [
        {
          id: 1,
          url: 'http://cdn.topartsintermational.com/door-left1.png',
          code: 1001,
        },
        {
          id: 2,
          url: 'http://cdn.topartsintermational.com/door-left2.png',
          code: 1002,
        },
        {
          id: 3,
          url: 'http://cdn.topartsintermational.com/door-left3.png',
          code: 1003,
        },
        {
          id: 4,
          url: 'http://cdn.topartsintermational.com/door-left4.png',
          code: 1004,
        },
        {
          id: 5,
          url: 'http://cdn.topartsintermational.com/door-left5.png',
          code: 1005,
        },
        {
          id: 6,
          url: 'http://cdn.topartsintermational.com/door-left6.png',
          code: 1006,
        },
        {
          id: 7,
          url: 'http://cdn.topartsintermational.com/door-left7.png',
          code: 1007,
        },
        {
          id: 8,
          url: 'http://cdn.topartsintermational.com/door-left8.png',
          code: 1008,
        },
        {
          id: 9,
          url: 'http://cdn.topartsintermational.com/door-left9.png',
          code: 1009,
        },
        {
          id: 10,
          url: 'http://cdn.topartsintermational.com/door-left10.png',
          code: 10010,
        },
        {
          id: 11,
          url: 'http://cdn.topartsintermational.com/door-left11.png',
          code: 10011,
        },
        {
          id: 12,
          url: 'http://cdn.topartsintermational.com/door-left12.png',
          code: 10012,
        },
        {
          id: 13,
          url: 'http://cdn.topartsintermational.com/door-left13.png',
          code: 10013,
        }
      ],
      rightBox: [],
      rightList: [
        {
          id: 1,
          url: 'http://cdn.topartsintermational.com/door-right1.png',
          code: 1001,
        },
        {
          id: 2,
          url: 'http://cdn.topartsintermational.com/door-right2.png',
          code: 1002,
        },
        {
          id: 3,
          url: 'http://cdn.topartsintermational.com/door-right3.png',
          code: 1003,
        },
        {
          id: 4,
          url: 'http://cdn.topartsintermational.com/door-right4.png',
          code: 1004,
        },
        {
          id: 5,
          url: 'http://cdn.topartsintermational.com/door-right5.png',
          code: 1005,
        },
        {
          id: 6,
          url: 'http://cdn.topartsintermational.com/door-right6.png',
          code: 1006,
        },
        {
          id: 7,
          url: 'http://cdn.topartsintermational.com/door-right7.png',
          code: 1007,
        },
        {
          id: 8,
          url: 'http://cdn.topartsintermational.com/door-right8.png',
          code: 1008,
        },
        {
          id: 9,
          url: 'http://cdn.topartsintermational.com/door-right9.png',
          code: 1009,
        },
        {
          id: 10,
          url: 'http://cdn.topartsintermational.com/door-right10.png',
          code: 10010,
        },
        {
          id: 11,
          url: 'http://cdn.topartsintermational.com/door-right11.png',
          code: 10011,
        },
        {
          id: 12,
          url: 'http://cdn.topartsintermational.com/door-right12.png',
          code: 10012,
        },
        {
          id: 13,
          url: 'http://cdn.topartsintermational.com/door-right13.png',
          code: 10013,
        }
      ],
    }
  },
  mounted () {
    this.leftList = shuffle(this.leftList)
    this.rightList = shuffle(this.rightList)
    const num = Math.ceil(Math.random() * 14)
    this.src = 'http://cdn.topartsintermational.com/end-' + num + '.mp4'
  },
  watch: {
    leftBox: function (val) {
      if (val[0] && val[0].flag && this.rightBox.length === 1) {
        const left = val[0]
        const right = this.rightBox[0]
        if (left.code === right.code) {
          this.visible = true
          // const audio = document.getElementById('audio')
          // audio.pause()
          const video = document.getElementsByClassName('endVideo')
          // video[0].muted = false
          setTimeout(() => {
            this.showVideo = true
            video[0].play()
          }, 1000)
        } else {
          this.$message.error('wrong, match failed')
        }
      }
    },
    rightBox: function (val) {
      if (val[0] && val[0].flag && this.leftBox.length === 1) {
        const left = this.leftBox[0]
        const right = val[0]
        if (left.code === right.code) {
          this.visible = true
          // const audio = document.getElementById('audio')
          // audio.pause()
          const video = document.getElementsByClassName('endVideo')
          // video[0].muted = false
          setTimeout(() => {
            this.showVideo = true
            video[0].play()
          }, 1000)
        } else {
          this.$message.error('wrong, match failed')
        }
      }
    },
    deep: true,
  },
  methods: {
    onClose () {
      const audio = document.getElementById('audio')
      audio.play()
    },
    // 列表拖拽结束，此处可以计算index，删除leftlist指定项
    onLeftEnd (ev) {
      if (ev.to.className === 'leftDrag') {
        this.$set(this.leftList[ev.oldIndex], 'flag', true)
      }
    },
    onRightEnd (ev) {
      if (ev.to.className === 'rightDrag') {
        this.$set(this.rightList[ev.oldIndex], 'flag', true)
      }
    },
    leftStart () {
      this.left = ''
    },
    leftEnd () {
      this.left = 'leftDoor'
    },
    rightStart () {
      this.right = ''
    },
    rightEnd () {
      this.right = 'rightDoor'
    },
    onLeftMove () {
      if (this.leftBox.length === 1) {
        const oldId = this.leftBox[0].id
        this.leftBox.pop()
        let q = this.leftList.find((item) => {
          return item.id === oldId
        })
        this.$delete(q, 'flag')
      }
    },
    onRightMove () {
      if (this.rightBox.length === 1) {
        const oldId = this.rightBox[0].id
        this.rightBox.pop()
        let q = this.rightList.find((item) => {
          return item.id === oldId
        })
        this.$delete(q, 'flag')
      }
    }
  }
}
</script>

<style scoped lang="less">
h1 p {
  margin: 0;
  padding: 0;
}
.box {
  width: 100%;
  min-width: 1240px;
  min-height: 100vh;
  background: url("http://cdn.topartsintermational.com/gameBg.jpg") no-repeat;
  background-size: 100% 100%;

  .limit-box {
    width: 1200px;
    position: relative;
    margin: auto;
  }
  .audio {
    display: none;
  }
  .intro-btn {
    position: absolute;
    top: 10%;
    right: 0;
    color: #fff;
    font-size: 32px;
    background-color: #ca3540;
    z-index: 1;
  }
  .index {
    position: absolute;
    top: 10%;
    left: 0;
    color: #fff;
    font-size: 32px;
    background-color: #ca3540;
    z-index: 1;
  }
  .door {
    display: flex;
    position: relative;
    padding-top: 96px;
    .endVideo {
      width: 622px;
      height: 432px;
      position: absolute;
      top: 376px;
      left: 290px;
      background: #000;
    }
    img {
      display: block;
      margin: auto;
    }
    .door-part {
      width: 100px;
      height: 150px;
      border: 1px solid #fff;
      background-color: red;
      position: absolute;
      .door-box {
        width: 100px;
        height: 150px;
      }
    }
    .door-left {
      top: 56%;
      left: 38%;
    }
    .door-right {
      top: 56%;
      right: 38%;
    }
  }
  .list-box {
    width: 1200px;
    display: flex;
    justify-content: space-around;
    background-color: #5c0108;
    color: #fff;
    padding-bottom: 48px;
    margin: 48px auto 0;
    .list {
      width: 48%;
      .dragArea {
        width: 100%;
        height: 150px;
        display: inline-block;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background: rgb(248 212 180);
          -webkit-box-shadow: inset 0 0 5px rgb(248 212 180);
        }
        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: #000;
          -webkit-box-shadow: inset 0 0 10px rgb(234 181 131);
        }

        .list-img {
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
  .introBox {
    min-height: 500px;
    background-color: rgba(92, 1, 8, 0.8);
    h1 {
      color: #fff;
      margin: 0;
      text-align: center;
      line-height: 3;
    }
    p {
      color: #fff;
      margin: 0;
      text-indent: 1em;
      line-height: 2;
      font-size: 18px;
      padding: 0 8px;
      text-align: justify;
      word-break: normal;
    }
  }
  .cont {
    width: 1200px;
    margin: 16px auto 0;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    .cont-menu {
      width: 45%;
      color: #fff;
      padding-left: 16px;
      p {
        margin: 0;
        padding: 0;
        line-height: 24px;
        font-weight: 500;
      }
    }
    .all-line {
      width: 100%;
      display: flex;
      .title {
        margin-right: 15px;
      }
      .des {
        margin-right: 10px;
      }
    }
  }
}

.undraggable {
  background-color: red;
}

.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
.img {
  display: block;
  width: 80px;
  margin-right: 10px;
}

</style>